* {
	box-sizing: border-box;
}

:root:root {
	--adm-color-primary: #4671fa;
	--adm-color-text: #1f1f1f;
	--adm-color-light: #999;
	--adm-badge-color: #ff5cd7;
}

body {
	margin: 0;
	font-size: 14px;
	font-family: var(--adm-font-family);
}

.adm-infinite-scroll {
	padding-top: 12px;
}

.flex1 {
	flex: 1;
}

.row {
	display: flex;
}

.row-center {
	display: flex;
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.col {
	display: flex;
	flex-direction: column;
}

.single-line-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ellipsis-multiline {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.adm-button {
	font-size: 1rem;
	font-weight: 500;
}

.adm-input-clear {
	padding: 0.5rem;
}

.adm-input-clear svg {
	display: block;
	font-size: 1.3rem !important;
}

.adm-toast-main {
	max-width: 75% !important;
}

.adm-tab-bar-wrap {
	height: 3.57rem;
}

.adm-tab-bar-item {
	border-top: 0.14rem solid #fff;
}

.adm-tab-bar-item-title {
	margin-top: -0.14rem;
	font-weight: 500;
	font-size: 1rem;
	color: #343234;
}

.adm-list-body {
	background: unset !important;
	border-width: 0 !important;
}

.adm-input-element {
	font-size: 1rem !important;
}

.login-bg {
	width: 100%;
	height: 23.5rem;
}

.login-logo {
	position: absolute;
	top: 7.5rem;
	left: calc(50% - 8.47rem);
	width: 16.93rem;
	height: 6.14rem;
}

.login-content {
	margin: -3.93rem 1.43rem 0;
}

.login-label {
	font-weight: 500;
	font-size: 1rem;
	color: #1f1f1f;
	line-height: 1.5rem;
}

.login-input {
	flex: auto;
	height: 3.43rem;
	margin: 0.71rem 0 1.43rem;
	padding: 0 0.86rem;
	border-radius: 1.71rem;
	border: 0.07rem solid #e5e5e5;
}

.login-password {
	position: relative;
	padding: 0;
}

.login-password-input {
	height: 100%;
	padding: 0 3rem 0 0.86rem;

	svg {
		font-size: var(--adm-font-size-12);
	}
}

.login-eye {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	padding: 0 0.86rem;
	svg {
		display: block;
		font-size: 1.3rem;
	}
}

.login-button {
	height: 3.14rem;
	margin-top: 2.86rem;
}

.navbar-back {
	width: 3.14rem;
	height: 3.14rem;
}

.navbar-back-img {
	width: 0.71rem;
	height: 1.21rem;
}

.navbar-title {
	text-align: center;
	max-width: 14.86rem;
	font-size: 1.14rem;
	color: #343234;
	font-weight: 500;
}

.home-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.home-content {
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0 1.14rem;
	background: #f1f4f9;
	border-top-right-radius: 1.79rem;
	border-top-left-radius: 1.79rem;
}

.header {
	position: relative;
	height: 10.71rem;
}

.header-bg {
	position: absolute;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 10.71rem;
}

/* calc(env(safe-area-inset-bottom)*var(--multiple)) */
.header-title {
	padding-left: 1.64rem;
	font-size: 1.43rem;
	line-height: 4.35rem;
	letter-spacing: -0.07rem;
	color: #fff;
	font-weight: 600;
}

.home-bottom {
	border-top: 0.07rem solid #ebedf0;
}

.active-border {
	border-top: 0.14rem solid #4671fa;
}

.line-cell {
	height: 0.43rem;
	margin-top: -0.57rem;
	background: rgba(70, 113, 250, 0.19);
	border-radius: 0.21rem;
}

.content-header {
	display: inline-block;
	margin: 1.79rem 0 0.57rem 0.57rem;
	font-size: 1.35rem;
	color: #28272d;
	line-height: 1.86rem;
	font-weight: bold;
}

.list-item {
	margin-top: 0.86rem;
	padding: 1.14rem 0.5rem;
	background: #feffff;
	border-radius: 0.86rem;
}

.info-text {
	margin-bottom: 0.71rem;
	font-size: 0.93rem;
	line-height: 1.29rem;
	color: #999;
	letter-spacing: -0.5px;
}

.info-text span {
	font-size: 1.14rem;
	line-height: 1.57rem;
	color: #343234;
	font-weight: 500;
}

.info-text-m {
	margin-bottom: 1rem;
}

.info-text-footer {
	margin-bottom: 0;
}

.list-item-content {
	margin: 0 0.64rem;
}

.image {
	width: 5.71rem;
	height: 5.71rem;
	border-radius: 0.57rem;
}

.item-goods {
	height: 5.71rem;
	margin-left: 0.57rem;
}

.item-goods-name {
	max-width: 15.79rem;
	font-size: 1rem;
	line-height: 1.29rem;
	color: #272828;
}

.mercari {
	width: 1.14rem;
	height: 1.14rem;
	margin-right: 0.57rem;
}

.buy {
	width: 1.71rem;
	height: 1.07rem;
	margin-right: 0.57rem;
}

.info-number {
	font-size: 0.86rem;
	line-height: 1.21rem;
	color: #a3a3a3;
}

.shop {
	width: 1.14rem;
	height: 1.14rem;
	margin: 0 0.57rem 0 1.14rem;
}

.service {
	height: 2.29rem;
	margin-top: 1.21rem;
	padding-left: 0.93rem;
	background: #f8f8f7;
	font-size: 0.86rem;
	color: #333;
	border-radius: 1.14rem;
}

.service span {
	font-size: 0.93rem;
	color: #343234;
	font-weight: 500;
}

.service-top {
	margin-top: 1rem;
}

.service-top1 {
	margin-top: 0;
}

.inquire-img {
	position: fixed;
	right: 0;
	bottom: 4.35rem;
	z-index: 999;
	width: 5.57rem;
	height: 5.57rem;
}

.mask-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.mask-content {
	width: 19.64rem;
	padding: 0 2rem 2.14rem;
	background: #feffff;
	border-radius: 1.71rem;
}

.mask-please {
	text-align: center;
	margin-top: 2rem;
	padding-bottom: 1.29rem;
	border-bottom: 0.04rem solid #e5e5e5;
	font-size: 1.14rem;
	line-height: 1.71rem;
	color: #262626;
	font-weight: 500;
}

.no-border {
	padding-bottom: 2.07rem;
	border-width: 0;
}

.code-input {
	height: 3.43rem;
	margin: 1.43rem 0 2.14rem;
	padding: 0 1.14rem;
	border-radius: 0.57rem;
	border: 0.07rem solid #e5e5e5;
}

.footer-btn {
	width: 6.43rem;
	height: 2.29rem;
	margin-left: 0.57rem;
}

.cancel-btn {
	margin-left: 0;
	background: #ebedf0;
	color: #737475;
}

.gray-btn {
	width: 40%;
	height: 2.86rem;
	margin: 0 0.93rem 0 1.14rem;
	background: #ebedf0;
	color: #646566;
}

.confirm-btn {
	width: 60%;
	height: 2.86rem;
	margin-right: 1.14rem;
}

.detail-content {
	overflow-y: scroll;
	padding: 0 0.79rem;
	background: #f1f4f9;
}

.detail-title {
	margin: 1.21rem 0 0 0.36rem;
	font-size: 1.14rem;
	line-height: 1.57rem;
	color: #343234;
	font-weight: 600;
}

.detail-cell-label {
	font-size: 0.93rem;
	line-height: 1.29rem;
	color: #999;
}

.detail-cell {
	margin: 0.43rem 0 0.86rem;
	padding: 1rem 0.93rem;
	background: #fff;
	border-radius: 1rem;
}

.detail-cell2 {
	margin: 1.14rem 0 !important;
}

.detail-cell1 {
	padding: 1.43rem 0.93rem;
}

.detail-cell3 {
	margin: 0.43rem 0 1.14rem;
}

.detail-cell4 {
	padding: 1.43rem 0.93rem;
}

.detail-cell-id {
	margin: 0.86rem 0;
	padding: 0.5rem 0.93rem;
	background: #fff;
	border-radius: 0.57rem;
}

.detail-text {
	font-size: 0.86rem;
	line-height: 1.4rem;
	color: #a3a3a3;
}

.detail-text span {
	color: #343234;
}

.detail-text1 {
	margin: 0.86rem 0 1.07rem;
}

.detail-text2 {
	margin-bottom: 0.57rem;
}

.detail-text3 {
	margin-bottom: 0.86rem;
}

.detail-text4 {
	padding: 0.79rem 0 0.86rem;
	border-top: 0.07rem solid #e9e9e9;
}

.detail-text-left {
	margin-left: 2.71rem;
}

.photo-cell {
	width: 4.07rem;
	height: 4.07rem;
	background: #fff;
	border-radius: 0.43rem;
	border: 0.07rem solid #dbdfe6;
}

.adm-image-uploader-cell {
	border-radius: 0.43rem;
}

.photo-img {
	width: 1.57rem;
	height: 1.26rem;
}

.cell-bottom {
	margin-bottom: 1.43rem;
}

.cell-bottom1 {
	margin-bottom: 1rem;
}

.form-value {
	font-size: 1.14rem;
	line-height: 1.57rem;
	color: #343234;
	font-weight: 500;
}

.-margin {
	margin-top: -0.29rem;
}

.form-label {
	position: relative;
	text-align: right;
	width: 7rem;
	letter-spacing: -0.1px;
	margin-right: 1.14rem;
	font-size: 1rem;
	color: #666;
	line-height: 1.5rem;
}

.form-label span {
	position: absolute;
	top: -0.71rem;
	right: 0;
	color: #ff0005;
}

.form-label div {
	margin-top: 0.36rem;
	font-size: 0.86rem;
	line-height: 1.21rem;
	color: #a3a3a3;
}

.form-label1 {
	width: 4.71rem;
	margin-right: 1.57rem;
}

.form-label2 {
	width: 7.93rem;
	margin-left: -0.93rem;
	letter-spacing: -0.4px;
}

.form-label3 span {
	margin-left: 0.36rem;
	position: unset;
	color: #ff0005;
}

.form-input {
	flex: 1;
	height: 2.57rem;
	padding: 0 1.14rem;
	border-radius: 0.57rem;
	border: 0.07rem solid #e5e5e5;
}

.form-input1 {
	margin: 1.07rem 0.64rem 2rem;
}

.selected-cell {
	margin: 1.57rem 0;
	font-size: 1rem;
	line-height: 1.43rem;
	color: #9f9f9f;
}

.selected-img {
	width: 1.43rem;
	height: 1.43rem;
	margin-left: 0.5rem;
}

.selected-img-margin {
	margin: 0 0.64rem;
}

.status-img {
	display: block;
	margin: auto;
	width: 2.86rem;
	height: 2.86rem;
	margin-top: 2.43rem;
}

.status-text {
	text-align: center;
	margin: 0.57rem 0 1.29rem;
	font-size: 1.29rem;
	line-height: 1.93rem;
	color: #262626;
	font-weight: 500;
}

.status-reason {
	font-size: 1rem;
}

.packaging-cell {
	height: 3.71rem;
	padding: 0 1rem;
	font-size: 1.14rem;
	line-height: 1.57rem;
	color: #343234;
	font-weight: 500;
	border-bottom: 0.07rem solid #e5e5e5;
}

.reason-cell {
	margin-top: 1.43rem;
	font-size: 1rem;
	line-height: 1.43rem;
	color: #9f9f9f;
}

.copy {
	width: 1rem;
	height: 1rem;
	margin: 0 0 -0.14rem 0.64rem;
}

.table {
	text-align: center;
	font-size: 1rem;
	color: #333;
	border-bottom: 0.07rem solid #eaeaea;
	letter-spacing: -0.5px;
}

.table-thead {
	height: 3.29rem;
	background: #f8f8f9;
	font-weight: 500;
}

.min-font {
	font-size: 0.93rem;
}

.table-thead-image {
	height: 4.2rem;
	width: 4.2rem;
	border-radius: 0.36rem;
}

.table-thead-1 {
	width: 30%;
}
.table-thead-2 {
	width: 20%;
}
.table-thead-3 {
	width: 20%;
}

.table-row {
	height: 5rem;
}

.table-pagination {
	height: 2.29rem;
	margin-top: 0.5rem;
	color: #b7babf;
}

.pagination-cell {
	height: 100%;
	padding: 0 1rem;
	color: var(--adm-color-primary);
	font-size: 1rem;
	font-weight: 500;
}

.arrow-cell {
	height: 100%;
	padding: 0 0.43rem;
}
